import { api } from "jiffy-api";
import { track } from "jiffy-analytics";
import { isStyleVisible } from "common/utils/ui/element";
import { chunkArray } from "common/utils/array";
import { isElementVisible } from "common/utils/ui/visibility";

const SELECTORS = {
  DELIVERY_SPINNER: ".js-plp-delivery-spinner",
  DELIVERY_DATE: ".js-plp-delivery-date",
  DELIVERY_CONTAINER: ".js-plp-dates-container"
};

// Global variable is used to sync different js chunks that call this module
const compositionIdsSent = window?.jiffy?.ui?.productsCompositionIdsSent || [];

const trackDeliveryDatesRendered = (renderTime, ajaxTime, additionalTags = {}) => {
  track("delivery_dates_rendered", {
    page: window?.jiffy?.ui?.productsRenderPage || "other",
    time_to_get_date: Math.floor(ajaxTime),
    time_to_render_date: Math.floor(renderTime),
    ...additionalTags
  });
};

const isSpinnerHidden = $el => {
  const $spinner = $el.querySelector(".js-plp-delivery-spinner");

  // Need the negated result
  return !isStyleVisible($spinner);
};

const fetchCompositionEstimates = compositionIds =>
  new Promise((resolve, reject) => {
    const requestStartTime = performance?.now() || 0;
    api.product
      .fetchCompositionEstimates({ composition_ids: compositionIds.join() })
      .then(data => {
        const requestEndTime = performance?.now() || 0;
        resolve([data, requestEndTime - requestStartTime]);
      })
      .catch(reject);
  });

const loadCompositionEstimates = rawCompositionIds => {
  const compositionIds = [...new Set(rawCompositionIds)];
  return fetchCompositionEstimates(compositionIds).then(([data, ajaxTime]) => {
    if (data.success) {
      Array.from(compositionIds).forEach(compositionId => {
        const { date, timeleft, one_ship_day: oneShipDay } = data.estimates[compositionId] || {};
        const $allContainers = document.querySelectorAll(
          `.product-card__delivery-row[data-composition-id="${compositionId}"]`
        );

        const renderEstimates = $container => {
          if (!date) {
            $container.querySelector(SELECTORS.DELIVERY_SPINNER).classList.add("hidden");
            $container.dataset.assignable = false;
            return;
          }

          $container
            .querySelector(SELECTORS.DELIVERY_DATE)
            .classList.toggle("product-card__delivery-date-cart--tomorrow", !!oneShipDay);

          $container.querySelector(SELECTORS.DELIVERY_DATE).setAttribute("data-date", date);
          $container.querySelector(SELECTORS.DELIVERY_DATE).setAttribute("data-timeleft", timeleft);
          $container.querySelector(SELECTORS.DELIVERY_DATE).innerHTML = date;
          $container.querySelector(SELECTORS.DELIVERY_CONTAINER).classList.remove("hidden");
          $container.querySelector(SELECTORS.DELIVERY_SPINNER).classList.add("hidden");
        };

        Array.from($allContainers).forEach(renderEstimates);
      });
    }

    return [data, ajaxTime];
  });
};

export const loadVisibleCompositionEstimates = () => {
  const renderStartTime = performance?.now() || 0;
  const estimatesRows = document.querySelectorAll(".js-plp-product-card-delivery");

  const visibleRows = Array.from(estimatesRows).filter(
    element => isElementVisible(element) && !isSpinnerHidden(element)
  );

  const compositionIds = Array.from(visibleRows)
    .filter(cell => cell.dataset.assignable === "true")
    .map(elem => elem.dataset.compositionId)
    // ensure we send each composition once
    .filter(id => !compositionIdsSent.includes(id));

  if (!compositionIds.length) {
    return;
  }

  compositionIdsSent.push(...compositionIds);
  loadCompositionEstimates(compositionIds).then(([_data, ajaxTime]) => {
    const renderEndTime = performance?.now() || 0;
    trackDeliveryDatesRendered(renderEndTime - renderStartTime, ajaxTime, {
      render_trigger: "scroll"
    });
  });
};

export const loadFirstFewRowsEstimates = () => {
  const $estimatesRows = document.querySelectorAll(".js-plp-product-card-delivery");

  // This is the visible items count in a viewport right now
  let visibleItemsCount = Array.from($estimatesRows).filter(
    $el => isElementVisible($el) && !isSpinnerHidden($el)
  ).length;

  // Happens in very small screen where no spinner is visible, i.e. iPhone SE, iPhone 12 Mini.
  // Delivery spinner is the source of visible product cards.
  // Hardcode as 2 items
  visibleItemsCount = visibleItemsCount === 0 ? 2 : visibleItemsCount;

  // All with visible spinners but not necessarily visible in current viewport
  const $estimateableRows = Array.from($estimatesRows).filter($el => !isSpinnerHidden($el));

  // Get two packs of visibleItemsCount in a query that receives only 2 items at a time
  // For example, if we visibleItemsCount is 5 (desktop), we will get 5 queries with 2 items each
  // If visibleItemsCount is 2 (mobile), we will get 2 queries with 2 items each
  const $batches = chunkArray($estimateableRows, 2).slice(0, visibleItemsCount);

  $batches.forEach($batch => {
    const compositionIds = $batch
      .filter(
        cell =>
          cell.dataset.assignable === "true" &&
          !compositionIdsSent.includes(cell.dataset.compositionId)
      )
      .map(elem => elem.dataset.compositionId);
    // No need to check if each compositionId is included in compositionIdsSent because this is the
    // first call
    compositionIdsSent.push(...compositionIds);
    // Launch each as promise, so no need to wait to execute one after the other.
    // The first call will ideally finish first.
    compositionIds.length &&
      loadCompositionEstimates(compositionIds).then(([_data, ajaxTime]) => {
        const renderStartTime = window?.jiffy?.timers?.productsRenderStartTime || 0;
        const renderEndTime = performance?.now() || 0;
        trackDeliveryDatesRendered(renderEndTime - renderStartTime, ajaxTime, {
          render_trigger: "load"
        });
      });
  });
};
